/**
 *
 * NoteForm
 *
 */
import 'app/components/scss/_buttons.scss';
import styled from 'styled-components/macro';
import { memo, useEffect } from 'react';
import { Button, Form, Input } from 'antd';
import InputPhone from 'app/components/InputPhone';

const ModeratorForm = memo((props: Props) => {
  const { modetatorInfo, onCancel, isAddmodetator, editChecks, onFinish } =
    props;
  const [form] = Form.useForm();
  useEffect(() => {
    if (!isAddmodetator) {
      form.setFieldsValue({
        id: `${modetatorInfo ? modetatorInfo?.id : '---'}`,
        first_name: `${modetatorInfo ? modetatorInfo?.first_name : '---'}`,
        last_name: `${modetatorInfo ? modetatorInfo?.last_name : '---'}`,
        email: `${modetatorInfo ? modetatorInfo?.email : '---'}`,
        contact_no: `${modetatorInfo ? modetatorInfo?.contact_no : '---'}`,
      });
    }
  }, [modetatorInfo, isAddmodetator, form]);

  return (
    <Div>
      <Form form={form} onFinish={props => onFinish({ props, form })}>
        <div className="row">
          <div className="col-md-10">
            <div className="row align-items-center">
              <div className="col-md-4">
                <div className="custom-form-input-wrap2 text-end custom-required-after">
                  <label htmlFor="">First Name</label>
                </div>
              </div>
              <div className="col-md-8">
                <div className="custom-form-input-wrap2">
                  <Form.Item
                    name="first_name"
                    rules={[
                      {
                        required: true,
                        message: 'First Name is required',
                      },
                    ]}
                  >
                    <Input
                      type={'text'}
                      placeholder={'First Name'}
                      disabled={editChecks?.isExpiryEditable}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-10">
            <div className="row align-items-center">
              <div className="col-md-4">
                <div className="custom-form-input-wrap2 text-end custom-required-after">
                  <label htmlFor="">Last Name</label>
                </div>
              </div>
              <div className="col-md-8">
                <div className="custom-form-input-wrap2">
                  <Form.Item
                    name="last_name"
                    rules={[
                      {
                        required: true,
                        message: 'Last Name is required',
                      },
                    ]}
                  >
                    <Input
                      type={'text'}
                      placeholder={'Last Name'}
                      disabled={editChecks?.isExpiryEditable}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-10">
            <div className="row align-items-center">
              <div className="col-md-4">
                <div className="custom-form-input-wrap2 text-end custom-required-after">
                  <label htmlFor="">Email</label>
                </div>
              </div>
              <div className="col-md-8">
                <div className="custom-form-input-wrap2">
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: 'Email is required',
                      },
                    ]}
                  >
                    <Input
                      type={'text'}
                      placeholder={'Email'}
                      disabled={editChecks?.isExpiryEditable}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-10">
            <div className="row align-items-center">
              <div className="col-md-4">
                <div className="custom-form-input-wrap2 text-end custom-required-after">
                  <label htmlFor="">Phone Number</label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="contact_no">
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'Phone is required.',
                      },
                    ]}
                    name="contact_no"
                  >
                    <InputPhone
                      country={'us'}
                      onlyCountries={['us']}
                      disableCountryCode
                      inputProps={{
                        autoFocus: false,
                        focus: false,
                      }}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-1"></div>

          <div className="col-md-2"></div>
        </div>

        <div className="row">
          <div className="col-md-6"></div>
          <div className="col-md-6">
            <div className="d-flex justify-content-end mt-2">
              <Button
                onClick={e => {
                  e.preventDefault();
                  onCancel();
                  form.resetFields();
                }}
                htmlType="submit"
                className="custom-white-btn me-4"
              >
                Cancel
              </Button>
              <Button htmlType="submit" className="custom-green-btn">
                Save
              </Button>
            </div>
          </div>
        </div>
      </Form>
    </Div>
  );
});
const Div = styled.div``;

interface Props {
  modetatorInfo?: any;
  onCancel?: any;
  isAddmodetator?: boolean;
  editChecks?: editChecks;
  onFinish: (e: any) => void;
}

export default ModeratorForm;
type editChecks = {
  isFullEditable: boolean;
  isExpiryEditable: boolean;
  isEditDisable: boolean;
};
