/**
 *
 * PublicRoutes
 *
 */
import React, { memo } from 'react';
import { SignIn } from '../../SignIn/Loadable';
import { ForgotPassword } from '../../ForgotPassword/Loadable';
import { ResetPassword } from '../../ResetPassword/Loadable';
import { ModeratorInvite } from '../../ModeratorInvite/Loadable';
import {
  SIGN_IN,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  MODERATOR_INVITE_ACCEPT,
} from '../constants';
import { Route, Switch } from 'react-router-dom';

interface Props {}

export const PublicRoutes = memo((props: Props) => {
  return (
    <Switch>
      <Route exact={true} path={SIGN_IN} component={SignIn} />
      <Route exact={true} path={FORGOT_PASSWORD} component={ForgotPassword} />
      <Route exact={true} path={RESET_PASSWORD} component={ResetPassword} />
      <Route
        exact={true}
        path={MODERATOR_INVITE_ACCEPT}
        component={ModeratorInvite}
      />
    </Switch>
  );
});
