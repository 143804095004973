import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.signIn || initialState;

export const selectUser = createSelector([selectSlice], state => state.user);

export const isLoading = createSelector(
  [selectSlice],
  state => state.isLoading,
);

export const selectSignIn = createSelector([selectSlice], state => state);
