/**
 *
 * Asynchronously loads the component for SignIn
 *
 */

import { LoadingWrapper } from '../../components/LoadingWrapper/index';
import { lazyLoad } from 'utils/loadable';

export const SignIn = lazyLoad(
  () => import('./index'),
  module => module.SignIn,
  {
    fallback: <LoadingWrapper />,
  },
);
