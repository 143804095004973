/**
 *
 * AppHeader
 *
 */
import './AppHeader.scss';
import Cookies from 'js-cookie';
import React, { memo } from 'react';
import { Avatar, message } from 'antd';
import { useDispatch } from 'react-redux';
import Logo from '../../../images/logo.png';
import { FaAngleDown } from 'react-icons/fa';
import { NavLink, useHistory } from 'react-router-dom';
import { useSignInSlice } from 'app/pages/SignIn/slice';
// import { selectUser } from 'app/pages/SignIn/slice/selectors';
import { signInConstants } from 'app/pages/SignIn/slice/constants';

interface Props {}

export const AppHeader = memo((props: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  // const user = useSelector(selectUser);
  const isModerator = Cookies.get('isModerator');
  useSignInSlice();
  const SignOut = () => {
    const adminAuthToken = Cookies.get('adminAuthToken');
    dispatch({
      type: signInConstants.SIGN_OUT_USER,
      payload: adminAuthToken,
      callback: response => {
        if (response.status === 200) {
          Cookies.remove('adminAuthToken');
          message.success('User has been logged out');
          history.push('/sign-in');
        }
      },
    });
    Cookies.remove('isModerator');
  };
  return (
    <div className="header">
      <div className="logo">
        <NavLink to={'/users'}>
          <img src={Logo} alt="" />
        </NavLink>
      </div>
      <div className="nav-wrapper">
        <ul className="nav justify-content-end">
          <li className="nav-item">
            <div className="user-dropdown">
              <div className="img-cover">
                <Avatar>
                  <span style={{ color: '#fff' }}>
                    A
                    {/* {user?.first_name ? user?.first_name.charAt(0) : ''} */}
                  </span>
                </Avatar>
              </div>
              <span>
                <FaAngleDown />
              </span>
              <div className="user-dropdown-wrapper">
                <ul className="user-dropdown-list">
                  <li
                    style={{
                      borderBottom: '1px solid #9ea09f9d',
                      pointerEvents: 'none',
                      cursor: 'default !important',
                    }}
                  >
                    {isModerator === 'true' ? <b>Moderator</b> : <b>Admin</b>}
                    {/* <b>{user?.first_name + ' ' + user?.last_name}</b> */}
                  </li>
                  <li onClick={SignOut} style={{ cursor: 'pointer' }}>
                    {' '}
                    Sign Out
                  </li>
                </ul>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
});
