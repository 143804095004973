/**
 *
 * Layout
 *
 */
import './LayoutPage.scss';
import { memo } from 'react';
import { Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { AppHeader } from 'app/components/AppHeader';

const { Content, Sider } = Layout;
const items = [
  {
    key: '1',
    label: 'Dashboard',
    className: 'customclass',
  },
  {
    key: '2',
    label: 'Users',
    className: 'customclass',
  },
  {
    key: '3',
    label: 'Leads',
    className: 'customclass',
  },
];

export const AppLayout = memo(({ children }: Props) => {
  const [currentKey, setcurrentKey] = useState('');
  useEffect(() => {
    const tempArr = items?.filter(
      (item: itemsType) =>
        window.location.pathname === `/${item?.label.toLowerCase()}`,
    );
    setcurrentKey(tempArr.length > 0 ? tempArr[0]?.key : '');
  }, []);
  return (
    <>
      <AppHeader />
      <Layout hasSider>
        <div className="row w-100">
          <div className="col-md-3">
            <Sider
              style={{
                overflow: 'auto',
                height: '100vh',
                position: 'fixed',
                left: 0,
                top: '96px',
                bottom: 0,
                background: '#91BC3D',
              }}
            >
              <div className="logo" />
              <Menu
                theme="dark"
                mode="inline"
                // items={items}
                selectedKeys={[currentKey]}
                style={{
                  background: '#91BC3D',
                }}
              >
                {items.map((item: itemsType) => (
                  <Menu.Item key={item?.key}>
                    <Link
                      style={{ textDecoration: 'none' }}
                      to={`/${item?.label.toLowerCase()}`}
                    >
                      {item?.label}
                    </Link>
                  </Menu.Item>
                ))}
              </Menu>
            </Sider>
          </div>
          <div className="col-md-12">
            <Layout className="site-layout" style={{ marginLeft: 200 }}>
              <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
                {children}
              </Content>
            </Layout>
          </div>
        </div>
      </Layout>
    </>
  );
});
type itemsType = { key: string; label: string; className: string };
interface Props {
  children: any;
}
