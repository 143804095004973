/**
 *
 * Paginator
 *
 */
import React from 'react';
import { Pagination } from 'antd';

export const Paginator = ({
  total,
  defaultCurrent,
  handlePagination,
  pageSize,
  className,
}: Props) => {
  return (
    <div className={className}>
      <Pagination
        onChange={(page, pageSize) => handlePagination(page, pageSize)}
        total={total}
        showTotal={(total, range) =>
          `${range[0]}-${range[1]} of ${total} items`
        }
        defaultPageSize={10}
        defaultCurrent={1}
        current={defaultCurrent}
        pageSize={pageSize}
        locale={{ items_per_page: '' }}
      />
    </div>
  );
};

type Props = {
  total: number;
  pageSize: number;
  defaultCurrent: number;
  handlePagination?: any;
  className: string;
};
