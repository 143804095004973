import formatMoney from 'accounting-js/lib/formatMoney.js';
import axios, { AxiosError } from 'axios';
import { message } from 'antd';
export const formatCurrency = (value, ...rest) => {
  if (value || value === 0) return formatMoney(value, ...rest);
  else return '-';
};
export const handleError = error => {
  if (axios.isAxiosError(error)) {
    const err: any = error as AxiosError;

    if (err?.response?.status === 500) {
      message.error(err?.response?.statusText);
      return;
    }
    if (err?.response?.data?.message && !err?.response?.data?.state) {
      message.error(err?.response?.data?.message);
      return;
    }
    if (
      err?.response?.data?.messages.length > 0 &&
      err?.response?.data?.messages[0] === 'Email not found'
    ) {
      message.error({
        content: 'Invalid email address. Please register a new account.',
        className: 'custom-msg-forget',
        duration: 100,
      });
      return;
    }
    if (err?.response?.data?.state === 'not_confirmed') {
      const errorMessage =
        'Your account has not yet been activated. Please contact the administrators ';
      message.error({
        content: (
          <span>
            {errorMessage}{' '}
            <a
              style={{ textDecoration: 'underline' }}
              href="mailto:info@liftoffleads.com"
            >
              here
            </a>
          </span>
        ),
        className: 'custom-msg',
        duration: 100,
      });
      return;
    }

    if (err?.response?.data?.error) {
      message.error(err?.response?.data?.error);
      return;
    } else {
      err?.response?.data?.messages?.forEach(m => {
        if (m === 'Reset password token is invalid') {
          message.error({
            content:
              'Invalid password reset token. Please look for a more recent email.',
            className: 'custom-msg-forget',
            duration: 100,
          });
        } else {
          message.error(m);
        }
      });
    }
  }
};

export const getPhoneMasking = (value, record) => {
  const countries = ['US', 'CA'];
  var cleaned = ('' + value).replace(/\D/g, '');
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (countries.includes(record?.country)) {
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
  }
  return value;
};

export const getPhoneMaskingInUS = (value, record) => {
  const countries = ['US', 'CA'];
  var cleaned = ('' + value).replace(/\D/g, '');
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  let country = 'US';
  if (countries.includes(country)) {
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
  }
  return value;
};
