import { LoadingWrapper } from '../../components/LoadingWrapper/index';
/**
 *
 * Asynchronously loads the component for UserDetail
 *
 */

import { lazyLoad } from 'utils/loadable';

export const UserDetail = lazyLoad(
  () => import('./index'),
  module => module.UserDetail,
  {
    fallback: <LoadingWrapper />,
  },
);
