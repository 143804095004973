import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { moderatorSaga } from './saga';
import { ModeratorState } from './types';

export const initalModeratorData = {
  first_name: '',
  full_name: '',
  last_name: '',
  email: '',
  contact_no: '',
  last_sign_in_at: '',
  country: '',
  id: '',
};

export const initialState: ModeratorState = {
  isLoading: false,
  moderator: [initalModeratorData],
  meta: {
    total_count: 1,
    total_pages: 10,
  },
  pageNo: 1,
  per_page: 50,
  sortParams: { order: 'ascend', field: '' },
  updateLoading: false,
  user: {
    id: '',
  },
};

const slice = createSlice({
  name: 'moderator',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
      state.updateLoading = action.payload;
    },
    fetchModeratorSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.updateLoading = false;
      const moderator = action?.payload?.data?.data;
      const data = moderator.map((moderator, index) => {
        return {
          id: `${moderator.id}`,
          first_name: `${moderator.attributes.first_name}`,
          full_name: `${moderator.attributes.full_name}`,
          email: `${moderator.attributes.email}`,
          contact_no: `${moderator.attributes.contact_no}`,
          last_sign_in_at: `${moderator.attributes.last_sign_in_at}`,
          country: `${moderator.attributes.country}`,
          last_name: `${moderator.attributes.last_name}`,
          confirmed_at: `${moderator.attributes.confirmed_at}`,
        };
      });

      state.meta = {
        total_count: action?.payload?.data?.meta?.total_count,
        total_pages: action?.payload?.data?.meta?.total_pages,
      };
      state.isLoading = false;
      state.pageNo = action?.payload?.pageNo;
      state.per_page = action?.payload?.per_page;
      state.sortParams.order = action?.payload?.order;
      state.sortParams.field = action?.payload?.field;
      state.isLoading = false;
      state.moderator = data;
    },
    fetchModeratorFaliure(state) {
      state.isLoading = false;
      state.moderator = [];
      state.updateLoading = false;
    },
    fetchAdminSuccess(state, action: PayloadAction<any>) {
      state.isLoading = false;
      state.user = action.payload.user;
    },
    fetchAdminFailed(state) {
      state.isLoading = false;
      state.user = {};
    },
  },
});

export const { actions: moderatorActions } = slice;

export const useModeratorSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: moderatorSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useModeratorSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
