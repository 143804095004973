/**
 *
 * Modal
 *
 */
import React, { memo, ReactChild } from 'react';
import styled from 'styled-components/macro';
import './CustomModal.scss';
import { Modal } from 'antd';

interface Props {
  title: string;
  children: ReactChild;
  isVisible: boolean;
  handleCancel?: () => void;
  modalWidth?: string | number;
}

export const CustomModal = memo((props: Props) => {
  return (
    <Div>
      <Modal
        title={props.title.length > 0 ? props.title : ''}
        footer={null}
        visible={props.isVisible}
        onCancel={props.handleCancel}
        width={props.modalWidth}
      >
        {props.children}
      </Modal>
    </Div>
  );
});

const Div = styled.div``;
