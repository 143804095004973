/**
 *
 * Asynchronously loads the component for ForgotPassword
 *
 */
import * as React from 'react';
import { lazyLoad } from 'utils/loadable';
import { LoadingWrapper } from '../../components/LoadingWrapper/index';

export const ForgotPassword = lazyLoad(
  () => import('./index'),
  module => module.ForgotPassword,
  {
    fallback: <LoadingWrapper />,
  },
);
