/**
 *
 * ProtectedRoutes
 *
 */
import React from 'react';
import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';
import moment from 'moment';
import Layout from '../../Layout';
import ModeratorLayout from '../../LayoutModerator';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  COUPONS_PAGE,
  SUBSCRIPTION_PAGE,
  USERS_PAGE,
  USER_DETAIL,
  DASHBOARD,
  MODERATOR_PAGE,
  LEADS_PAGE,
  REPORTSCHEDULER,
  CONTACT_TIMING,
  BLOCKING,
} from '../constants';
import { Report } from 'app/pages/Report/Loadable';
import { Coupons } from '../../Coupons/Loadable';
import { HomePage } from '../../HomePage/Loadable';
import { Dashboard } from '../../Dashboard/Loadable';
import { UserDetail } from '../../UserDetail/Loadable';
import { Subscriptions } from '../../Subscription/Loadable';
import { DATE_TIME_FORMAT } from 'utils/constants/constants';
import { Moderator } from 'app/pages/Moderator';
import { Leads } from 'app/pages/Leads/Loadable';
import { ContactTiming } from 'app/pages/ContactTiming/Loadable';
import { Blocking } from 'app/pages/Blocking/Loadable';
const AuthWrapper = ({ component: Component }) => {
  const adminAuthToken = Cookies.get('adminAuthToken');
  const isModerator = Cookies.get('isModerator');

  if (!adminAuthToken) {
    return <Redirect to="/sign-in" />;
  }
  if (adminAuthToken) {
    let decoded: { exp: number } = jwt_decode(adminAuthToken);
    const expiry_time = moment.unix(decoded?.exp).format(DATE_TIME_FORMAT);
    const current_time = moment().format(DATE_TIME_FORMAT);
    const isAfter = moment(current_time).isAfter(moment(expiry_time));
    if (isAfter) {
      return <Redirect to="/sign-in" />;
    }
  }
  if (isModerator === 'true') {
    return (
      <ModeratorLayout>
        <Component />
      </ModeratorLayout>
    );
  } else if (isModerator === 'false') {
    return (
      <Layout>
        <Component />
      </Layout>
    );
  } else {
    return (
      <Layout>
        <Component />
      </Layout>
    );
  }
};

export const ProtectedRoutes = () => {
  const isModerator = Cookies.get('isModerator');
  if (isModerator === 'true') {
    return (
      <Switch>
        <Route
          path={USERS_PAGE}
          component={() => <AuthWrapper component={HomePage} />}
        />
        <Route
          path={USER_DETAIL}
          component={() => <AuthWrapper component={UserDetail} />}
        />
        <Route
          path={DASHBOARD}
          component={() => <AuthWrapper component={Dashboard} />}
        />
        <Route
          path={LEADS_PAGE}
          component={() => <AuthWrapper component={Leads} />}
        />
      </Switch>
    );
  } else {
    return (
      <Switch>
        <Route
          path={DASHBOARD}
          component={() => <AuthWrapper component={Dashboard} />}
        />
        <Route
          path={USERS_PAGE}
          component={() => <AuthWrapper component={HomePage} />}
        />
        <Route
          path={LEADS_PAGE}
          component={() => <AuthWrapper component={Leads} />}
        />
        <Route
          path={REPORTSCHEDULER}
          component={() => <AuthWrapper component={Report} />}
        />
        <Route
          path={MODERATOR_PAGE}
          component={() => <AuthWrapper component={Moderator} />}
        />
        <Route
          path={COUPONS_PAGE}
          component={() => <AuthWrapper component={Coupons} />}
        />
        <Route
          path={USER_DETAIL}
          component={() => <AuthWrapper component={UserDetail} />}
        />
        <Route
          path={SUBSCRIPTION_PAGE}
          component={() => <AuthWrapper component={Subscriptions} />}
        />
        <Route
          path={CONTACT_TIMING}
          component={() => <AuthWrapper component={ContactTiming} />}
        />
        <Route
          path={BLOCKING}
          component={() => <AuthWrapper component={Blocking} />}
        />

        <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
      </Switch>
    );
  }
};
