import React from 'react';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Form, Input, Spin, Tooltip, Typography } from 'antd';
import { CustomSortIcon } from 'app/components/CustomSortIcon';
import { getPhoneMaskingInUS } from 'utils';
import { AiFillCheckCircle } from 'react-icons/ai';
import { MdCancel } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { selectModeratorLoading } from '../slice/selectors';
import moment from 'moment';

const CustomUsersHeader = (
  sortParams,
  editingKey,
  edit,
  moderatorLoading,
  setpayload,
  payload,
  handleToggle,
  cellname,
  handleDeleteModerator,
  handleEditModerator,
) => {
  return [
    {
      title: ({ sortColumns }) =>
        CustomSortIcon('Name', 'full_name', sortColumns),
      dataIndex: 'full_name',
      key: 'full_name',
      sorter: true,
      editable: true,
      width: '13%',
      defaultSortOrder:
        sortParams.field === 'full_name' ? sortParams.order : '',
      render: (value, record) => (
        <div className="d-flex justify-content-between align-items-center">
          <div style={{ width: '270px' }}>{value}</div>
        </div>
      ),
    },
    {
      title: ({ sortColumns }) => CustomSortIcon('Email', 'Email', sortColumns),
      dataIndex: 'email',
      key: 'email',
      sorter: true,
      editable: true,
      width: '13%',
      defaultSortOrder: sortParams.field === 'email' ? sortParams.order : '',
      render: (value, record) => (
        <div className="d-flex justify-content-between align-items-center">
          <div style={{ width: '270px' }}>{value}</div>
        </div>
      ),
    },
    {
      title: ({ sortColumns }) =>
        CustomSortIcon('Verified', 'Verified', sortColumns),
      dataIndex: 'confirmed_at',
      key: 'confirmed_at',
      sorter: true,
      editable: true,
      width: '13%',
      align: 'center',
      defaultSortOrder:
        sortParams.field === 'confirmed_at' ? sortParams.order : '',
      render: (value, record) => (value === 'null' ? 'No' : 'Yes'),
    },
    {
      title: ({ sortColumns }) =>
        CustomSortIcon('Phone', 'contact_no', sortColumns),
      dataIndex: 'contact_no',
      key: 'contact_no',
      width: '13%',
      sorter: true,
      editable: true,
      defaultSortOrder:
        sortParams.field === 'contact_no' ? sortParams.order : '',
      render: (value, record) => (
        <div className="d-flex justify-content-between align-items-center">
          <div style={{ width: '270px' }}>
            {getPhoneMaskingInUS(value, record)}
          </div>
        </div>
      ),
    },
    {
      title: ({ sortColumns }) =>
        CustomSortIcon('Last Login', 'last_sign_in_at', sortColumns),
      dataIndex: 'last_sign_in_at',
      key: 'last_sign_in_at',
      width: '13%',
      sorter: true,
      editable: true,
      defaultSortOrder:
        sortParams.field === 'last_sign_in_at' ? sortParams.order : '',
      render: (value, record) => (
        <div className="d-flex justify-content-between align-items-center">
          <div style={{ width: '270px' }}>
            {value === 'null' || undefined || null
              ? '--'
              : moment(value).format('M/DD/YYYY hh:mm a')}
          </div>
        </div>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      width: '12.5%',
      render: (text, record) => {
        return (
          <div style={{ display: 'flex', alignContent: 'center' }}>
            <Tooltip title={'Edit Moderator'}>
              <EditOutlined
                onClick={e => {
                  handleEditModerator(record);
                }}
                style={{
                  marginRight: '15px',
                  color: 'rgb(54, 157, 242)',
                  cursor: 'pointer',
                  fontSize: '1.4rem',
                }}
              />
            </Tooltip>
            <Tooltip title="Delete Moderator">
              <DeleteOutlined
                onClick={() => handleDeleteModerator(record)}
                className="delete-icon"
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];
};

export default CustomUsersHeader;

export const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  recordId,
  record,
  index,
  cancel,
  save,
  children,
  cellname,
  ...restProps
}) => {
  const emailLoading = useSelector(selectModeratorLoading);
  return (
    <td {...restProps}>
      {editing && record?.id === recordId ? (
        <Spin tip="Updating..." spinning={emailLoading}>
          <div className="d-flex align-item-center">
            <Form.Item name={dataIndex} className="edit-input">
              <Input />
            </Form.Item>
            <div className="edit-actions">
              <span>
                <Typography.Link
                  onClick={() => save(record.id, dataIndex)}
                  className="edit-check-icon"
                >
                  <AiFillCheckCircle />
                </Typography.Link>

                <MdCancel className="edit-cancel-icon" onClick={cancel} />
              </span>
            </div>
          </div>
        </Spin>
      ) : (
        children
      )}
    </td>
  );
};
