/**
 *
 * Moderator
 *
 */
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { moderatorConstants } from './slice/constants';
import { Form, Spin, Input, Button } from 'antd';
import {
  selectpageNo,
  selectperPage,
  selectSortParams,
  selectModerators,
  selectmeta,
  selectAdmin,
  selectModeratorLoading,
} from './slice/selectors';
import { useModeratorSlice } from '../Moderator/slice';
import { Paginator } from 'app/components/Paginator';
import { DataTable } from 'app/components/DataTable';
import { BiSearch } from 'react-icons/bi';
import { useDebounce } from 'utils/StringUtils/stringUtils';
import CustomUsersHeader, { EditableCell } from './Columns';
import { isLoading } from '../SignIn/slice/selectors';
import { CustomModal } from 'app/components/CustomModal';
import ModeratorForm from 'app/components/ModeratorForm';
import ConfirmModal from 'app/components/ConfirmModal';

interface Props {}

export function Moderator(props: Props) {
  const dispatch = useDispatch();
  const storePageNo = useSelector(selectpageNo);
  const storeperPage = useSelector(selectperPage);
  const sortParams = useSelector(selectSortParams);
  const fetchedModerators = useSelector(selectModerators);
  const perPage = useSelector(selectperPage);
  const moderatorLoading = useSelector(selectModeratorLoading);
  const signinLoading = useSelector(isLoading);
  const loggedInUser = useSelector(selectAdmin);
  const meta: {
    total_count: number;
    total_pages: number;
  } = useSelector(selectmeta);
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = React.useState<string>('');
  const [recordId, setrecordId] = React.useState<string>('');
  const [cellname, setCellName] = React.useState<string>('');
  const [openEditModerator, setOpenEditModerator] =
    React.useState<boolean>(false);
  const [selectedRecord, setSelectedRecord] = React.useState<any>({});

  const [openAddModerator, setOpenAddModerator] =
    React.useState<boolean>(false);

  const [openDeleteModerator, setOpenDeleteModerator] =
    React.useState<boolean>(false);

  const [queryString, setQueryString] = React.useState<string>('');

  React.useEffect(() => {
    dispatch({
      type: moderatorConstants.FETCH_ADMIN,
    });
    // eslint-disable-next-line
  }, []);

  const delayedQuery = useDebounce(queryString, 600);
  useModeratorSlice();
  React.useEffect(() => {
    dispatch({
      type: moderatorConstants.FETCH_MODERATOR,
      payload: {
        pageNo: storePageNo,
        per_page: storeperPage,
        field: sortParams?.field,
        order: sortParams?.order,
        queryString: queryString,
      },
    });
    // eslint-disable-next-line
  }, [dispatch, delayedQuery]);

  const paginationHandler = React.useCallback(
    (page, pageSize) => {
      dispatch({
        type: moderatorConstants.FETCH_MODERATOR,
        payload: {
          pageNo: page,
          per_page: pageSize,
          order: sortParams?.order,
          field: sortParams?.field,
          queryString: queryString,
        },
      });
    },
    [sortParams?.order, sortParams?.field, dispatch],
  );

  const handleSorting = React.useCallback(
    (pagination, filters, sorter, extra) => {
      const { field, order } = sorter;
      dispatch({
        type: moderatorConstants.FETCH_MODERATOR,
        payload: {
          pageNo: storePageNo,
          per_page: storeperPage,
          field: field,
          order: order,
          queryString: queryString,
        },
      });
    },
    [dispatch, storeperPage, storePageNo, queryString],
  );

  const [payload, setpayload] = React.useState<{
    id: string;
    status: string;
    cc_email: string;
    city: string;
    company_name: string;
    company_url: string;
    contact_no: string;
    country: string;
    email: string;
    first_name: string;
    full_name: string;
    is_cc_active: string;
    last_name: string;
    market: string;
    notes: string;
    state: string;
    plan_id: string;
  }>({
    id: '',
    status: '',
    cc_email: '',
    city: '',
    company_name: '',
    company_url: '',
    contact_no: '',
    country: '',
    email: '',
    first_name: '',
    full_name: '',
    is_cc_active: '',
    last_name: '',
    market: '',
    notes: '',
    state: '',
    plan_id: '',
  });

  const edit = (record, key) => {
    form.setFieldsValue({
      company_name: '',
      ...record,
    });
    setEditingKey(record.key);
    setrecordId(record?.id);
    // setCellName('');
    setCellName(key);
  };

  const handleToggle = (e, id) => {
    dispatch({
      type: moderatorConstants.FETCH_MODERATOR,
      payload: {
        id: id,
        is_cc_active: e,
        is_toggle: true,
      },
      callback: () => {
        dispatch({
          type: moderatorConstants.FETCH_MODERATOR,
          payload: {
            pageNo: storePageNo,
            per_page: perPage,
            field: sortParams?.field,
            order: sortParams?.order,
            queryString: queryString,
          },
        });
      },
    });
  };

  function handleDeleteModerator(record) {
    setOpenDeleteModerator(true);
    setSelectedRecord(record);
  }

  function handleEditModerator(record) {
    setOpenEditModerator(true);
    setOpenAddModerator(false);
    setSelectedRecord(record);
  }

  const columns = CustomUsersHeader(
    sortParams,
    editingKey,
    edit,
    moderatorLoading,
    setpayload,
    payload,
    handleToggle,
    cellname,
    handleDeleteModerator,
    handleEditModerator,
  ).map(col => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: record => ({
        record,
        dataIndex: col.dataIndex,
        editing: record.key === editingKey,
      }),
    };
  });
  const cancel = () => {
    setEditingKey('');
  };

  const save = (id, dataIndex) => {
    let obj = {
      id: id,
      setEditingKey,
    };
    obj[cellname] = form.getFieldValue(`${dataIndex}`);
    dispatch({
      type: moderatorConstants.UPDATE_MODERATOR,
      payload: obj,
      callback: () => {
        dispatch({
          type: moderatorConstants.FETCH_MODERATOR,
          payload: {
            pageNo: storePageNo,
            per_page: perPage,
            field: sortParams?.field,
            order: sortParams?.order,
            queryString: queryString,
          },
        });
      },
    });
  };

  async function onFinishAdd({ props, form }) {
    let isEdit = openEditModerator;
    if (isEdit) {
      setOpenAddModerator(false);
      setOpenEditModerator(false);
      dispatch({
        type: moderatorConstants.UPDATE_MODERATOR,
        payload: {
          user: {
            ...props,
          },
          userId: selectedRecord.id,
        },
        onErrorCallback: () => {
          setOpenAddModerator(false);
          setOpenEditModerator(false);
          setSelectedRecord({});
          form.resetFields();
        },
        callback: () => {
          setOpenAddModerator(false);
          setOpenEditModerator(false);
          setSelectedRecord({});
          form.resetFields();
          dispatch({
            type: moderatorConstants.FETCH_MODERATOR,
            payload: {
              pageNo: storePageNo,
              per_page: perPage,
              field: sortParams?.field,
              order: sortParams?.order,
              queryString: queryString,
            },
          });
        },
      });
    } else {
      setOpenAddModerator(false);
      setOpenEditModerator(false);
      dispatch({
        type: moderatorConstants.ADD_MODERATOR,
        payload: {
          user: {
            ...props,
          },
          adminid: loggedInUser.id,
        },
        onErrorCallback: () => {
          setOpenAddModerator(false);
          setOpenEditModerator(false);
          setSelectedRecord({});
          form.resetFields();
        },
        callback: () => {
          setOpenAddModerator(false);
          setOpenEditModerator(false);
          setSelectedRecord({});
          form.resetFields();
          dispatch({
            type: moderatorConstants.FETCH_MODERATOR,
            payload: {
              pageNo: storePageNo,
              per_page: perPage,
              field: sortParams?.field,
              order: sortParams?.order,
              queryString: queryString,
            },
          });
        },
      });
    }
  }

  function onDelete() {
    dispatch({
      type: moderatorConstants.DELETE_MODERATOR,
      payload: {
        id: selectedRecord.id,
      },
      onErrorCallback: () => {
        setOpenAddModerator(false);
        setOpenEditModerator(false);
        setSelectedRecord({});
        setOpenDeleteModerator(false);
      },
      callback: () => {
        setOpenAddModerator(false);
        setOpenEditModerator(false);
        setSelectedRecord({});
        setOpenDeleteModerator(false);
        dispatch({
          type: moderatorConstants.FETCH_MODERATOR,
          payload: {
            pageNo: storePageNo,
            per_page: perPage,
            field: sortParams?.field,
            order: sortParams?.order,
            queryString: queryString,
          },
        });
      },
    });
  }

  const ModeratorModal = () => {
    if (openEditModerator) {
      return (
        <CustomModal
          title={'Edit Moderator'}
          isVisible={openEditModerator}
          handleCancel={() => {
            setOpenAddModerator(false);
            setOpenEditModerator(false);
            setSelectedRecord({});
          }}
        >
          <ModeratorForm
            isAddmodetator={false}
            onFinish={onFinishAdd}
            modetatorInfo={selectedRecord}
            onCancel={() => {
              setSelectedRecord({});
              setOpenAddModerator(false);
              setOpenEditModerator(false);
            }}
          />
        </CustomModal>
      );
    } else if (openAddModerator) {
      return (
        <CustomModal
          title={'Add Moderator'}
          isVisible={openAddModerator}
          handleCancel={() => {
            setOpenAddModerator(false);
            setOpenEditModerator(false);
            setSelectedRecord({});
          }}
        >
          <ModeratorForm
            isAddmodetator={true}
            onFinish={onFinishAdd}
            modetatorInfo={{}}
            onCancel={() => {
              setSelectedRecord({});
              setOpenAddModerator(false);
              setOpenEditModerator(false);
            }}
          />
        </CustomModal>
      );
    }
  };
  return (
    <Spin
      spinning={moderatorLoading}
      size="large"
      tip={signinLoading ? 'Signing Out...' : 'Fetching Moderators ...'}
      style={{ marginTop: '10%' }}
    >
      <div
        className={
          fetchedModerators.length > 10
            ? 'mt-5 table-scroll users-table-wrapper'
            : 'mt-5 users-table-wrapper'
        }
      >
        <ConfirmModal
          handleCancel={() => setOpenDeleteModerator(false)}
          isVisible={openDeleteModerator}
          title="Delete Confirmation"
          isDelete
          onCancel={() => setOpenDeleteModerator(false)}
          message="Are you sure? you want to delete this Moderator?"
          textCenter="text-center"
          onSubmit={() => {
            onDelete();
          }}
        />
        {ModeratorModal()}
        <div className="col-12 row">
          <div className="col-10" />
          <Button
            onClick={() => {
              setOpenAddModerator(true);
              setOpenEditModerator(false);
              setSelectedRecord({});
            }}
            className="custom-green-btn col-2"
            type="primary"
          >
            Add Moderator
          </Button>
        </div>
        <div className="col-12 row" style={{ marginTop: '3%' }}>
          <div className="col-8"></div>
          <div className="col-lg-4 col-12">
            <div className=" search-bar-wrap">
              <Input
                allowClear={true}
                type="text"
                className="custom-search-bar"
                placeholder="Search"
                onChange={e => setQueryString(e.target.value)}
              />
              <span className="search-icon">
                {' '}
                <BiSearch />{' '}
              </span>
            </div>
          </div>
        </div>
        <Form form={form} component={false}>
          <DataTable
            columns={columns}
            data={fetchedModerators ?? []}
            loading={false}
            meta={meta}
            onRowClick={() => {}}
            currentPage={storePageNo}
            onChange={handleSorting}
            components={{
              body: {
                cell: props =>
                  EditableCell({ ...props, recordId, cancel, save, cellname }),
              },
            }}
          />
        </Form>
      </div>
      {fetchedModerators.length > 0 && (
        <Paginator
          className="mt-5"
          pageSize={perPage}
          total={meta?.total_count}
          defaultCurrent={storePageNo}
          handlePagination={paginationHandler}
        />
      )}
    </Spin>
  );
}
