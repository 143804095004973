import axios from 'axios';
import { notification } from 'antd';
import Cookies from 'js-cookie';
// import Cookies from 'js-cookie';
axios.defaults.baseURL = window.__config__.API_URL;
// for demo only
// axios.defaults.baseURL = 'https://liftoffleads-backend.herokuapp.com/api/v1/';

const postReq = (url, data, adminAuthToken) => {
  return axios.post(url, data);
};

const putReq = (url, data, adminAuthToken) => axios.put(url, data);

const patchReq = (url, data, adminAuthToken) => axios.patch(url, data);

const getReq = (url, adminAuthToken) => axios.get(url);

const deleteReq = (url, data = null, headerData) =>
  axios.delete(url, { params: data });

const throwNotification = (type, title, description) => {
  notification[type]({
    message: title,
    description,
  });
};

axios.interceptors.request.use(
  config => {
    const adminAuthToken = Cookies.get('adminAuthToken');
    if (adminAuthToken) {
      config.headers['Authorization'] = adminAuthToken;
    }
    // config.headers['Content-Type'] = 'application/json';
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);
axios.interceptors.response.use(
  response => response,
  error => {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      if (
        error?.response?.data?.error === 'Invalid segment encoding' ||
        error?.response?.data?.error === 'revoked token' ||
        error?.response?.data?.error === 'nil user' ||
        error?.response?.data?.error ===
          'You need to sign in or sign up before continuing.' ||
        error?.response?.data?.error === 'Signature verification raised'
      ) {
        Cookies.remove('adminAuthToken');
        window.location.href = window.location.origin + '/sign-in';
      }
      return Promise.reject(error);
    }
    if (
      error?.response?.status === 401 ||
      error?.response?.status === 400 ||
      error?.response?.status === 404 ||
      error?.response?.status === 422 ||
      error?.response?.status === 500
    ) {
      return Promise.reject(error);
    }
    return Promise.reject(error);
  },
);
export { postReq, getReq, putReq, patchReq, deleteReq, throwNotification };
