import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { signInSaga } from './saga';
import { SignInState } from './types';

export const initialState: SignInState = {
  isLoading: false,
  user: {},
  adminAuthToken: '',
};

const slice = createSlice({
  name: 'signIn',
  initialState,
  reducers: {
    setIsLoadingTrue(state) {
      state.isLoading = true;
    },
    setIsLoadingFalse(state) {
      state.isLoading = false;
    },
    signInUserSuccess(state, action: PayloadAction<any>) {
      const { user } = action.payload;
      state.user = user;
    },
    updateUser(state, action: PayloadAction<any>) {
      const { user } = action.payload;
      state.user = user;
    },
    signInUserFailed(state) {
      state.isLoading = false;
      state.adminAuthToken = '';
      state.user = {};
    },
    updateUserFailed(state) {
      state.isLoading = false;
      state.adminAuthToken = '';
      state.user = {};
    },
    signOutUserSuccess(state) {
      state.isLoading = false;
      state.adminAuthToken = '';
      state.user = {};
    },
  },
});

export const { actions: signInActions } = slice;

export const useSignInSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: signInSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useSignInSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
