/**
 *
 * Asynchronously loads the component for Dashboard
 *
 */

import { lazyLoad } from 'utils/loadable';
import { LoadingWrapper } from '../../components/LoadingWrapper/index';

export const Dashboard = lazyLoad(
  () => import('./index'),
  module => module.Dashboard,
  {
    fallback: <LoadingWrapper />,
  },
);
