import React from 'react';
import { Button } from 'antd';
import { CustomModal } from '../CustomModal';
import '../scss/_buttons.scss';
const ConfirmModal = ({
  isVisible,
  onCancel,
  title,
  message,
  onSubmit,
  handleCancel,
  isDelete = false,
  textCenter = '',
}) => {
  return (
    <CustomModal
      title={title}
      isVisible={isVisible}
      handleCancel={handleCancel}
    >
      <div>
        <div className="text-center ">{message}</div>
        <div className="col-md-12">
          <div className="d-flex justify-content-center mt-4">
            <Button
              htmlType="submit"
              className="custom-white-btn me-4"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              htmlType="submit"
              className={isDelete ? 'custom-red-btn' : `custom-green-btn`}
              onClick={onSubmit}
            >
              Confirm
            </Button>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default ConfirmModal;
