/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import 'antd/dist/antd.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { GlobalStyle } from '../styles/global-styles';
import { ProtectedRoutes } from './pages/Routes/ProtectedRoutes';
import { PublicRoutes } from './pages/Routes/PublicRoutes';

export function App() {
  const { i18n } = useTranslation();
  return (
    <BrowserRouter>
      <Helmet
        defaultTitle="Liftoff Leads (Admin) "
        htmlAttributes={{ lang: i18n.language }}
      >
        <meta name="description" content="A React Boilerplate application" />
      </Helmet>
      <PublicRoutes />
      <ProtectedRoutes />
      <GlobalStyle />
    </BrowserRouter>
  );
}
