/**
 *
 * DataTable
 *
 */
import * as React from 'react';
import { Table } from 'antd';
import { TableComponents } from 'rc-table/lib/interface';

// interface Props {}

export function DataTable({
  columns,
  data,
  loading,
  onRowClick,
  meta,
  pagination = false,
  currentPage = 1,
  paginationHandler,
  components,
  onChange,
  ...rest
}: propType) {
  return (
    <div>
      <Table
        components={components}
        dataSource={data}
        columns={columns}
        onChange={onChange}
        sortDirections={['descend', 'ascend', 'descend']}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event: React.MouseEvent) => {
              onRowClick && onRowClick(record, event);
            },
            onDoubleClick: event => {}, // double click row
            onContextMenu: event => {}, // right button click row
            onMouseEnter: event => {}, // mouse enter row
            onMouseLeave: event => {}, // mouse leave row
          };
        }}
        {...rest}
        pagination={false}
      />
    </div>
  );
}
type propType = {
  columns: any;
  data: any;
  loading: boolean;
  onRowClick?: any;
  pagination?: boolean;
  paginationHandler?: any;
  meta?: {
    total_count: number;
    total_pages: number;
  };
  currentPage?: number;
  components?: TableComponents<any> | undefined;
  onChange?: (pagination, filters, sorter, extra) => void;
};
