import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'types';
import { initialState } from '.';

const selectSlice = (state: RootState) => state.moderator || initialState;

export const selectModerator = createSelector([selectSlice], state => state);

export const selectModeratorLoading = createSelector(
  [selectSlice],
  state => state?.isLoading,
);

export const selectModerators = createSelector(
  [selectSlice],
  state => state?.moderator,
);

export const selectpageNo = createSelector(
  [selectSlice],
  state => state.pageNo,
);

export const selectperPage = createSelector(
  [selectSlice],
  state => state.per_page,
);

export const selectSortParams = createSelector(
  [selectSlice],
  state => state.sortParams,
);

export const selectUpdateLaoding = createSelector(
  [selectSlice],
  state => state.updateLoading,
);

export const selectAdmin = createSelector([selectSlice], state => state.user);

export const selectmeta = createSelector([selectSlice], state => state.meta);
