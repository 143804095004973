/**
 *
 * CustomSortIcon
 *
 */
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';
export const CustomSortIcon = (title, key, sortColumns) => {
  let sortedColumn = sortColumns?.find(({ column }) => column.key === key);
  return (
    <div key={key}>
      {title}
      {sortedColumn ? (
        sortedColumn.order === 'ascend' ? (
          <AiOutlineArrowUp />
        ) : (
          <AiOutlineArrowDown />
        )
      ) : null}
    </div>
  );
};
