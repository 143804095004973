export const USER_LOGIN_STATES = {
  NOT_ACTIVATED: 'not_activated',
  NOT_REGISTERED: 'not_registered',
  NOT_CONFIRMED: 'not_confirmed',
};

export const DATE_FORMAT = 'M/DD/YYYY';

export const DATE_TIME_FORMAT = 'M/DD/YYYY hh:mm A';

export const STATES = {
  US: [
    {
      name: 'Alabama',
      id: 'AL',
    },
    {
      name: 'Alaska',
      id: 'AK',
    },
    {
      name: 'American Samoa',
      id: 'AS',
    },
    {
      name: 'Arizona',
      id: 'AZ',
    },
    {
      name: 'Arkansas',
      id: 'AR',
    },
    {
      name: 'California',
      id: 'CA',
    },
    {
      name: 'Colorado',
      id: 'CO',
    },
    {
      name: 'Connecticut',
      id: 'CT',
    },
    {
      name: 'Delaware',
      id: 'DE',
    },
    {
      name: 'District Of Columbia',
      id: 'DC',
    },
    {
      name: 'Federated States Of Micronesia',
      id: 'FM',
    },
    {
      name: 'Florida',
      id: 'FL',
    },
    {
      name: 'Georgia',
      id: 'GA',
    },
    {
      name: 'Guam',
      id: 'GU',
    },
    {
      name: 'Hawaii',
      id: 'HI',
    },
    {
      name: 'Idaho',
      id: 'ID',
    },
    {
      name: 'Illinois',
      id: 'IL',
    },
    {
      name: 'Indiana',
      id: 'IN',
    },
    {
      name: 'Iowa',
      id: 'IA',
    },
    {
      name: 'Kansas',
      id: 'KS',
    },
    {
      name: 'Kentucky',
      id: 'KY',
    },
    {
      name: 'Louisiana',
      id: 'LA',
    },
    {
      name: 'Maine',
      id: 'ME',
    },
    {
      name: 'Marshall Islands',
      id: 'MH',
    },
    {
      name: 'Maryland',
      id: 'MD',
    },
    {
      name: 'Massachusetts',
      id: 'MA',
    },
    {
      name: 'Michigan',
      id: 'MI',
    },
    {
      name: 'Minnesota',
      id: 'MN',
    },
    {
      name: 'Mississippi',
      id: 'MS',
    },
    {
      name: 'Missouri',
      id: 'MO',
    },
    {
      name: 'Montana',
      id: 'MT',
    },
    {
      name: 'Nebraska',
      id: 'NE',
    },
    {
      name: 'Nevada',
      id: 'NV',
    },
    {
      name: 'New Hampshire',
      id: 'NH',
    },
    {
      name: 'New Jersey',
      id: 'NJ',
    },
    {
      name: 'New Mexico',
      id: 'NM',
    },
    {
      name: 'New York',
      id: 'NY',
    },
    {
      name: 'North Carolina',
      id: 'NC',
    },
    {
      name: 'North Dakota',
      id: 'ND',
    },
    {
      name: 'Northern Mariana Islands',
      id: 'MP',
    },
    {
      name: 'Ohio',
      id: 'OH',
    },
    {
      name: 'Oklahoma',
      id: 'OK',
    },
    {
      name: 'Oregon',
      id: 'OR',
    },
    {
      name: 'Palau',
      id: 'PW',
    },
    {
      name: 'Pennsylvania',
      id: 'PA',
    },
    {
      name: 'Puerto Rico',
      id: 'PR',
    },
    {
      name: 'Rhode Island',
      id: 'RI',
    },
    {
      name: 'South Carolina',
      id: 'SC',
    },
    {
      name: 'South Dakota',
      id: 'SD',
    },
    {
      name: 'Tennessee',
      id: 'TN',
    },
    {
      name: 'Texas',
      id: 'TX',
    },
    {
      name: 'Utah',
      id: 'UT',
    },
    {
      name: 'Vermont',
      id: 'VT',
    },
    {
      name: 'Virgin Islands',
      id: 'VI',
    },
    {
      name: 'Virginia',
      id: 'VA',
    },
    {
      name: 'Washington',
      id: 'WA',
    },
    {
      name: 'West Virginia',
      id: 'WV',
    },
    {
      name: 'Wisconsin',
      id: 'WI',
    },
    {
      name: 'Wyoming',
      id: 'WY',
    },
  ],
  CA: [
    {
      name: 'Alberta',
      id: 'AB',
    },
    {
      name: 'British Columbia',
      id: 'BC',
    },
    {
      name: 'Manitoba',
      id: 'MB',
    },
    {
      name: 'New Brunswick',
      id: 'NB',
    },
    {
      name: 'Newfoundland and Labrador',
      id: 'NL',
    },
    {
      name: 'Northwest Territories',
      id: 'NT',
    },
    {
      name: 'Nova Scotia',
      id: 'NS',
    },
    {
      name: 'Nunavut',
      id: 'NU',
    },
    {
      name: 'Ontario',
      id: 'ON',
    },
    {
      name: 'Prince Edward Island',
      id: 'PE',
    },
    {
      name: 'Quebec',
      id: 'QC',
    },
    {
      name: 'Saskatchewan',
      id: 'SK',
    },
    {
      name: 'Yukon Territory',
      id: 'YT',
    },
  ],
};
export const ADDITIONAL_OPTIONS = [
  { name: 'Universal', id: '' },
  { name: 'Unassigned', id: 'nil' },
];
export const UNASSIGN = [{ name: 'Unassign', id: 'nil' }];
export const DAYS_FILTER = [
  { name: '30 days', id: '30' },
  { name: '60 days', id: '60' },
  { name: '90 days', id: '90' },
  { name: '180 days', id: '180' },
];

export const REPORT_DAYS = [
  { name: '1', id: '1' },
  { name: '2', id: '2' },
  { name: '3', id: '3' },
  { name: '4', id: '4' },
  { name: '5', id: '5' },
  { name: '6', id: '6' },
  { name: '7', id: '7' },
  { name: '8', id: '8' },
  { name: '9', id: '9' },
  { name: '10', id: '10' },
  { name: '11', id: '11' },
  { name: '12', id: '12' },
  { name: '13', id: '13' },
  { name: '14', id: '14' },
  { name: '15', id: '15' },
  { name: '16', id: '16' },
  { name: '17', id: '17' },
  { name: '18', id: '18' },
  { name: '19', id: '19' },
  { name: '20', id: '20' },
  { name: '21', id: '21' },
  { name: '22', id: '22' },
  { name: '23', id: '23' },
  { name: '24', id: '24' },
  { name: '25', id: '25' },
  { name: '26', id: '26' },
  { name: '27', id: '27' },
  { name: '28', id: '28' },
  { name: '29', id: '29' },
  { name: '30', id: '30' },
  { name: '31', id: '31' },
  { name: '32', id: '32' },
];
