////////////////////////////////////////
export const SIGN_IN = '/sign-in';
export const DASHBOARD = '/dashboard';
export const USERS_PAGE = '/users';
export const LEADS_PAGE = '/leads';
export const MODERATOR_PAGE = '/moderators';
export const COUPONS_PAGE = '/coupons';
export const RESET_PASSWORD = '/reset-password';
export const SUBSCRIPTION_PAGE = '/subscriptions';
export const FORGOT_PASSWORD = '/forgot-password';
export const MODERATOR_INVITE_ACCEPT = '/invitation/accept';
export const USER_DETAIL = '/user-detail/:id';
export const REPORTSCHEDULER = '/report';
export const BLOCKING = '/blocking';
export const CONTACT_TIMING = '/contacttiming';
////////////////////////////////////////
