/**
 *
 * LoadingWrapper
 *
 */
import * as React from 'react';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import styled from 'styled-components/macro';

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface Props {}

export const LoadingWrapper = (props: Props) => {
  return (
    <Wrapper>
      <LoadingIndicator />
    </Wrapper>
  );
};
