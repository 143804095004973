import { message } from 'antd';
import { deleteReq, postReq, getReq } from 'config/Requests';
import { call, put, takeLatest } from 'redux-saga/effects';
import { signInActions as actions } from '.';
import { signInConstants } from './constants';
import Cookies from 'js-cookie';
import { handleError } from 'utils';
function* signInUserSaga(obj) {
  const { payload, callback } = obj;

  try {
    yield put(actions.setIsLoadingTrue());
    const response = yield call(postReq, '/auth/sign_in', payload, null);
    callback(response);
    yield put(
      actions.signInUserSuccess({ user: response.data.data.attributes }),
    );
    yield put(actions.setIsLoadingFalse());
  } catch (error: any) {
    if (error?.response?.data?.message) {
      message.error({
        content: error?.response?.data?.message,
        className: 'custom-msg',
      });
    } else {
      handleError(error);
    }

    yield put(actions.signInUserFailed());
  }
}
function* fetchUser(obj) {
  const { callback } = obj;
  const auth = Cookies.get('adminAuthToken');
  try {
    yield put(actions.setIsLoadingTrue());
    const response = yield call(getReq, '/users/authorized_user', auth);
    callback(response);
    yield put(actions.updateUser({ user: response.data.data.attributes }));
    yield put(actions.setIsLoadingFalse());
  } catch (error) {
    handleError(error);
    yield put(actions.updateUserFailed());
  }
}

function* signOutUserSaga(obj) {
  const auth = Cookies.get('adminAuthToken');
  const { payload, callback } = obj;
  try {
    yield put(actions.setIsLoadingTrue());
    const response = yield call(deleteReq, '/auth/sign_out', payload, auth);
    callback(response);
    yield put(actions.signOutUserSuccess());
    yield put(actions.setIsLoadingFalse());
  } catch (error) {
    handleError(error);
  }
}

export function* signInSaga() {
  yield takeLatest(signInConstants.SIGN_IN_USER, signInUserSaga);
  yield takeLatest(signInConstants.FETCH_USER, fetchUser);
  yield takeLatest(signInConstants.SIGN_OUT_USER, signOutUserSaga);
}
