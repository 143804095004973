import { moderatorActions as actions } from '.';
import { moderatorConstants } from './constants';
import { call, put, takeLatest } from 'redux-saga/effects';
import { getReq, postReq, putReq, deleteReq } from 'config/Requests';
import { handleError } from './../../../../utils/index';
import Cookies from 'js-cookie';
import { message } from 'antd';

function* fetchModerators(obj) {
  const { pageNo, per_page, order, field, queryString } = obj?.payload;
  const isFullName = field === 'full_name' ? true : false;
  try {
    yield put(actions.setLoading(true));
    let requestUrl = `/users?role=moderator&page=${pageNo}&per_page=${per_page}&order=${order}&field=${
      isFullName ? 'last_name' : field
    }`;
    if (queryString.length > 0) {
      requestUrl = `/users/search?role=moderator&query=${queryString}&page=${pageNo}&per_page=${per_page}&order=${order}&field=${
        isFullName ? 'last_name' : field
      }`;
    }
    const response = yield call(getReq, requestUrl);
    yield put(
      actions.fetchModeratorSuccess({
        data: response?.data,
        pageNo,
        per_page,
        order,
        field,
      }),
    );
  } catch (error) {
    console.log('err', error);
    yield put(actions.fetchModeratorFaliure());
  }
}

function* updateModerator(obj) {
  const { payload, callback, onErrorCallback } = obj;
  const { user, userId } = payload;
  try {
    yield put(actions.setLoading(true));
    yield call(putReq, `users/${userId}`, user);
    message.success('Moderator Updated sucessfully');
    callback();
  } catch (error) {
    onErrorCallback();
    yield put(actions.setLoading(false));
    handleError(error);
  }
}

function* fetchAdmin(obj) {
  const auth = Cookies.get('adminAuthToken');
  try {
    yield put(actions.setLoading(true));
    const response = yield call(getReq, '/users/authorized_user', auth);
    yield put(actions.fetchAdminSuccess({ user: response.data.data }));
    yield put(actions.setLoading(false));
  } catch (error) {
    handleError(error);
    yield put(actions.fetchAdminFailed());
  }
}

function* addModerator(obj) {
  const { payload, callback, onErrorCallback } = obj;
  const { user, adminid } = payload;
  try {
    yield put(actions.setLoading(true));
    yield call(postReq, `/users/${adminid}/create_moderator`, user);
    message.success('Moderator added sucessfully');
    callback();
  } catch (error) {
    yield put(actions.setLoading(false));
    onErrorCallback();
    handleError(error);
  }
}

function* deleteModerator(obj) {
  const {
    payload: { id },
    callback,
  } = obj;
  try {
    yield put(actions.setLoading(true));
    yield call(deleteReq, `users/${id}`);
    callback();
    message.success('Moderator deleted sucessfully');
  } catch (error) {
    yield put(actions.setLoading(false));
    handleError(error);
  }
}

export function* moderatorSaga() {
  yield takeLatest(moderatorConstants.FETCH_MODERATOR, fetchModerators);
  yield takeLatest(moderatorConstants.UPDATE_MODERATOR, updateModerator);
  yield takeLatest(moderatorConstants.ADD_MODERATOR, addModerator);
  yield takeLatest(moderatorConstants.FETCH_ADMIN, fetchAdmin);
  yield takeLatest(moderatorConstants.DELETE_MODERATOR, deleteModerator);
}
