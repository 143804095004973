/**
 *
 * Asynchronously loads the component for ModeratorInvite
 *
 */

import { lazyLoad } from 'utils/loadable';

export const ModeratorInvite = lazyLoad(
  () => import('./index'),
  module => module.ModeratorInvite,
);
